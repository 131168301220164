import { booleanAttribute, Component, input, model, output } from '@angular/core';
import { ButtonComponent } from '../../basic/button/button.component';

@Component({
  selector: 'app-form-buttons',
  standalone: true,
  imports: [
    ButtonComponent
  ],
  templateUrl: './form-buttons.component.html'
})
export class FormButtonsComponent {
  public formIsSubmitting = model.required<boolean>();
  public submitText = input($localize`Save`);
  public hideCancel = input(false, { transform: booleanAttribute });
  public canSubmit = input(true, { transform: booleanAttribute });
  public onCancel = output<void>();
}
