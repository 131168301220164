<div class="flex flex-col gap-4 md:max-w-screen-lg md:mx-auto">
  <h1 class="text-xl font-bold">{{ title }}</h1>

  @if (errorMessage) {
    <p class="text-red-600">{{ errorMessage }}</p>
  } @else {
    @if (data$ | async; as data) {
        <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data)" class="flex flex-col gap-4">
            <app-form-language-container>
                <div slot="dutch">
                  <app-form-input i18n-label label="Organisation" formControlName="nlOrganisation" isRequired="true" bgColor="white"></app-form-input>
                  <app-form-input i18n-label label="Role" formControlName="nlRole" isRequired="true" bgColor="white"></app-form-input>
                  <app-form-textarea id="descNL" i18n-label label="Description" formControlName="nlDescription" isRequired="true" appAutogrow="descEN" bgColor="white"></app-form-textarea>
                </div>
                <div slot="english">
                  <app-form-input i18n-label label="Organisation" formControlName="enOrganisation" isRequired="false" bgColor="white"></app-form-input>
                  <app-form-input i18n-label label="Role" formControlName="enRole" isRequired="false" bgColor="white"></app-form-input>
                  <app-form-textarea id="descEN" i18n-label label="Description" formControlName="enDescription" isRequired="false" appAutogrow="descNL" bgColor="white"></app-form-textarea>
                </div>
            </app-form-language-container>
            <div class="flex flex-col gap-4 md:flex-row">
              <app-form-input i18n-label label="Start date" appDatePicker formControlName="startDate" isRequired="true" class="flex-1"></app-form-input>
              <app-form-input i18n-label label="End date" appDatePicker formControlName="endDate" isRequired="false" class="flex-1"></app-form-input>
            </div>
            <app-form-tools [controlArray]="data.form.get('tools')"></app-form-tools>
            @if (data.errorMessage) {
                <p class="text-red-600">{{ data.errorMessage }}</p>
            }
            <app-form-buttons [canSubmit]="data.form.valid && hasChanges()" [(formIsSubmitting)]="isSubmitting" (onCancel)="onCancel()"></app-form-buttons>
        </form>
    } @else {
        <div role="status" class="flex flex-col gap-4">
            <app-form-language-container>
                <div slot="dutch" class="flex flex-col gap-4">
                    <app-form-input i18n-label label="Organisation" bgColor="white" isLoading="true"></app-form-input>
                    <app-form-input i18n-label label="Role" bgColor="white" isLoading="true"></app-form-input>
                    <app-form-textarea i18n-label label="Description" bgColor="white" isLoading="true"></app-form-textarea>
                </div>
                <div slot="english" class="flex flex-col gap-4">
                  <app-form-input i18n-label label="Organisation" bgColor="white" isLoading="true"></app-form-input>
                  <app-form-input i18n-label label="Role" bgColor="white" isLoading="true"></app-form-input>
                  <app-form-textarea i18n-label label="Description" bgColor="white" isLoading="true"></app-form-textarea>
                </div>
            </app-form-language-container>
            <div class="flex flex-col gap-4 md:flex-row">
              <app-form-input i18n-label label="Start date" isLoading="true" class="flex-1"></app-form-input>
              <app-form-input i18n-label label="End date" isLoading="true" class="flex-1"></app-form-input>
            </div>
            <app-form-tools isLoading="true"></app-form-tools>
            <span class="sr-only">Loading...</span>
        </div>
    }
}
</div>
