import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecruiteeService {
  constructor(private http: HttpClient) {
  }

  public import(userId: string, recruiteeId: string): Observable<void> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http
      .post(
        `/api/recruitee/createProfile/${recruiteeId}`,
        JSON.stringify(userId),
        {
          headers: headers,
          responseType: 'text'
        })
      .pipe(
        catchError((err: HttpErrorResponse, _) => {
          throw new Error(err.error);
        }),
        map(_ => {})
      );
  }
}
