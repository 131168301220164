import { booleanAttribute, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Dropdown } from 'flowbite';
import { IsMobileService } from '../services/is-mobile.service';

@Directive({
  selector: '[appDropdown]',
  standalone: true
})
export class DropdownDirective implements OnInit {
  private dropdown: Dropdown;

  @Input({ required: true })
  public dropdownTrigger: HTMLElement;

  @Input()
  public dropdownShowTriggerOnEnter: HTMLElement | undefined;

  @Input({ transform: booleanAttribute })
  public closeOnSelect = true;

  constructor(private elem: ElementRef, private isMobileService: IsMobileService) {
  }

  public ngOnInit(): void {
    const options = {};
    Object
      .keys(this.dropdownTrigger.dataset)
      .filter(attributeName => attributeName.startsWith('dropdown'))
      .forEach(attributeName => {
        const optionName = attributeName.slice(8, 9).toLowerCase() + attributeName.slice(9);
        options[optionName] = this.dropdownTrigger.dataset[attributeName];
      });

    this.dropdown = new Dropdown(
      this.elem.nativeElement,
      this.dropdownTrigger,
      options);

      if (this.dropdownShowTriggerOnEnter && this.isMobileService.isNotMobile()) {
        this.dropdownTrigger.hidden = true;

        this.dropdownShowTriggerOnEnter.addEventListener('pointerenter', () => {
          this.dropdownTrigger.hidden = false;
        });

        this.dropdownShowTriggerOnEnter.addEventListener('pointerleave', () => {
          this.dropdown.hide();
          this.dropdownTrigger.hidden = true;
        });
      }
  }

  @HostListener('click')
  public onClick(): void {
    if (this.closeOnSelect) {
      this.dropdown.hide();
    }
  }
}
