<div class="flex flex-col gap-4 md:max-w-screen-lg md:mx-auto">
  <h1 class="text-xl font-bold">{{ title }}</h1>

  @if (errorMessage) {
    <p class="text-red-600">{{ errorMessage }}</p>
  } @else {
    @if (data$ | async; as data) {
      <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data)" class="flex flex-col gap-4">
        <div class="flex flex-col gap-4 md:flex-row">
          <app-form-input i18n-label label="Institute name" formControlName="nameInstitute" isRequired="true" class="flex-1"></app-form-input>
          <app-form-select i18n-label label="Education level" formControlName="educationLevel" [options]="educationLevels" isRequired="true" class="flex-1"></app-form-select>
        </div>
        <app-form-language-container>
          <div slot="dutch">
            <app-form-input i18n-label label="Education name" formControlName="nlName" bgColor="white" isRequired="true"></app-form-input>
          </div>
          <div slot="english">
            <app-form-input i18n-label label="Education name" formControlName="enName" bgColor="white"></app-form-input>
          </div>
        </app-form-language-container>
        <div class="flex flex-col gap-4 md:flex-row">
          <app-form-input i18n-label label="Start date" appDatePicker formControlName="startDate" isRequired="true" class="flex-1"></app-form-input>
          <app-form-input i18n-label label="End date" appDatePicker formControlName="endDate" isRequired="false" class="flex-1"></app-form-input>
        </div>
        <app-form-checkbox i18n-label label="Graduated" formControlName="hasGraduated"></app-form-checkbox>
        @if (data.errorMessage) {
            <p class="text-red-600">{{ data.errorMessage }}</p>
        }
        <app-form-buttons [canSubmit]="data.form.valid && hasChanges()" [(formIsSubmitting)]="isSubmitting" (onCancel)="onCancel()"></app-form-buttons>
      </form>
    } @else {
        <div role="status" class="flex flex-col gap-4">
          <div class="flex flex-col gap-4 md:flex-row">
            <app-form-input i18n-label label="Institute name" isLoading="true" class="flex-1"></app-form-input>
            <app-form-select i18n-label label="Education level" isLoading="true" class="flex-1"></app-form-select>
          </div>
          <app-form-language-container>
            <div slot="dutch">
              <app-form-input i18n-label label="Education name" isLoading="true"></app-form-input>
            </div>
            <div slot="english">
              <app-form-input i18n-label label="Education name" isLoading="true"></app-form-input>
            </div>
          </app-form-language-container>
          <div class="flex flex-col gap-4 md:flex-row">
            <app-form-input i18n-label label="Start date" isLoading="true" class="flex-1"></app-form-input>
            <app-form-input i18n-label label="End date" isLoading="true" class="flex-1"></app-form-input>
          </div>
          <app-form-checkbox i18n-label label="Graduated" isLoading="true"></app-form-checkbox>
          <span class="sr-only">Loading...</span>
        </div>
    }
  }
</div>
