import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Profile } from 'src/app/model/profile';
import { SoftSkill } from 'src/app/model/softSkill';
import { BaseProfileDataForm } from '../base-profile-data-form';
import { FormButtonsComponent } from 'src/app/core/components/form/form-buttons/form-buttons.component';
import { FormLanguageContainerComponent } from 'src/app/core/components/form/form-language-container/form-language-container.component';
import { FormInputComponent } from 'src/app/core/components/form/form-input/form-input.component';

@Component({
  selector: 'app-soft-skill-form',
  standalone: true,
  imports: [
    AsyncPipe,
    FormButtonsComponent,
    FormInputComponent,
    FormLanguageContainerComponent,
    ReactiveFormsModule
  ],
  templateUrl: './soft-skill-form.component.html',
})
export class SoftSkillFormComponent extends BaseProfileDataForm<SoftSkill> {
  @Input()
  public id: string;
  public dataName = $localize`soft skill`;

  constructor(route: ActivatedRoute, router: Router, profileService: ProfileService) {
    super(route, router, profileService);
  }

  protected getInitialValue(profile: Profile, valueId: string): SoftSkill | null {
    return profile.softSkills.find(softSkill => softSkill.id === valueId);
  }

  protected createForm(softSkill: SoftSkill | null): FormGroup {
    return new FormGroup({
      id: new FormControl(softSkill?.id),
      nlName: new FormControl(softSkill?.nameSoftSkillNL, Validators.required),
      enName: new FormControl(softSkill?.nameSoftSkillEN)
    });
  }
  protected areFormValuesEqual(initialValue: any, currentValue: any): boolean {
    return initialValue['id'] === currentValue['id']
      && initialValue['nlName'] === currentValue['nlName']
      && (initialValue['enName'] ?? '') === (currentValue['enName'] ?? '');
  }

  protected save(profileId: string, form: FormGroup): Observable<any> {
    return this.profileService.addSoftSkill(
      profileId,
      {
        id: form.value['id'],
        nameSoftSkillNL: form.value['nlName'],
        nameSoftSkillEN: form.value['enName'] ?? '',
      });
  }
}
