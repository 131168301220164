<div class="md:max-w-screen-lg md:mx-auto">
  <h1 i18n class="text-xl font-bold mb-4">Download CV</h1>

  @if (data$ | async; as data) {
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <app-label i18n-value value="Profile" [forId]="selectProfile.id()" isRequired="true"></app-label>
        <app-select #selectProfile [value]="data.selectedProfile?.id" [options]="data.options.profiles" isRequired="true" (onSelectionChanged)="onProfileSelectionChange($event)"></app-select>
      </div>
      <form [formGroup]="data.form" (ngSubmit)="generateCV(data)" class="flex flex-col gap-4">
        <app-form-select i18n-label label="Language" [options]="languageOptions" formControlName="language" isRequired="true"></app-form-select>
        <app-form-select i18n-label label="Template" [options]="templateOptions" formControlName="template" isRequired="true"></app-form-select>
        <div class="flex flex-col">
          <app-form-checkbox i18n-label label="Include photo in CV" formControlName="usePhoto"></app-form-checkbox>
          @if (!data.selectedProfile.professional.usePhoto) {
            <span i18n class="text-orange-400">The selected user has not allowed their profile photo to be used.</span>
          }
        </div>
        <app-form-select i18n-label label="Summary" [options]="data.options.summaries" formControlName="summary" isRequired="true"></app-form-select>
        <div class="flex flex-1 gap-4 flex-col md:flex-row">
          <app-form-assignments [controlArray]="data.form.get('assignments')" [assignments]="data.selectedProfile?.assignments" class="flex-1 md:max-h-[240.8px]"></app-form-assignments>
          <div class="flex flex-col flex-1 gap-4">
            <app-form-select i18n-label label="Maximum number of detailed assignments" [options]="data.options.maxNrOfDetailedAssignments" formControlName="maxNrOfDetailedAssignments" isRequired="true"></app-form-select>
            <app-form-select i18n-label label="Maximum number of educations" [options]="data.options.maxNrOfEducations" formControlName="maxNrOfEducations" isRequired="true"></app-form-select>
            <app-form-select i18n-label label="Maximum number of certificates" [options]="data.options.maxNrOfCertificates" formControlName="maxNrOfCertificates" isRequired="true"></app-form-select>
          </div>
        </div>
        @if (errorMessage) {
            <p class="mt-4 text-red-600">{{ errorMessage }}</p>
        }
        <app-form-buttons [canSubmit]="data.form.valid" [formIsSubmitting]="isGenerating" i18n-submitText submitText="Generate" hideCancel="true" mustBeDirtyToSubmit="false"></app-form-buttons>
      </form>
    </div>
  } @else {
    <div role="status" class="flex flex-col gap-4">
      <app-form-select i18n-label label="Profile" isLoading="true"></app-form-select>
      <app-form-select i18n-label label="Language" isLoading="true"></app-form-select>
      <app-form-select i18n-label label="Template" isLoading="true"></app-form-select>
      <app-form-checkbox i18n-label label="Include photo in CV" isLoading="true"></app-form-checkbox>
      <app-form-select i18n-label label="Summary" isLoading="true"></app-form-select>
      <div class="flex flex-1 gap-4 flex-col md:flex-row">
        <app-form-assignments isLoading="true" class="flex-1 md:max-h-[240.8px]"></app-form-assignments>
        <div class="flex flex-col flex-1 gap-4">
          <app-form-select i18n-label label="Maximum number of detailed assignments" isLoading="true"></app-form-select>
          <app-form-select i18n-label label="Maximum number of educations" isLoading="true"></app-form-select>
          <app-form-select i18n-label label="Maximum number of certificates" isLoading="true"></app-form-select>
        </div>
      </div>
      <span class="sr-only">Loading...</span>
    </div>
  }
</div>