import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Profile } from 'src/app/model/profile';
import { Tool, ToolLevel, toolLevelToName, ToolType, toolTypeToName } from 'src/app/model/tool';
import { ModalDeleteComponent } from 'src/app/core/components/modal-delete/modal-delete.component';
import { FormInputComponent } from 'src/app/core/components/form/form-input/form-input.component';
import { SelectOption } from 'src/app/core/components/basic/select/select-option.model';
import { BaseProfileDataForm } from '../base-profile-data-form';
import { FormButtonsComponent } from 'src/app/core/components/form/form-buttons/form-buttons.component';
import { FormSelectComponent } from 'src/app/core/components/form/form-select/form-select.component';

@Component({
  selector: 'app-tool-form',
  standalone: true,
  imports: [
    AsyncPipe,
    FormButtonsComponent,
    FormInputComponent,
    FormSelectComponent,
    ModalDeleteComponent,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './tool-form.component.html'
})
export class ToolFormComponent extends BaseProfileDataForm<Tool> {
  @Input()
  public id: string;
  public dataName = $localize`tool or technique`;

  public toolTypes: SelectOption<string>[] = Object
    .values(ToolType)
    .map(toolType => ({
      value: ToolType[toolType],
      label: toolTypeToName(toolType)
    }));

  public toolLevels: SelectOption<string>[] = Object
    .values(ToolLevel)
    .map(toolLevel => ({
      value: ToolLevel[toolLevel],
      label: toolLevelToName(toolLevel)
    }));

  constructor(route: ActivatedRoute, router: Router, profileService: ProfileService) {
    super(route, router, profileService);
  }

  protected getInitialValue(profile: Profile, valueId: string): Tool | null {
    return profile.tools.find(tool => tool.id === valueId);
  }

  protected createForm(tool: Tool | null): FormGroup {
    return new FormGroup({
      id: new FormControl(tool?.id),
      name: new FormControl(tool?.name, Validators.required),
      toolType: new FormControl(tool?.toolType, Validators.required),
      toolLevel: new FormControl(tool?.level, Validators.required),
    });
  }

  protected areFormValuesEqual(initialValue: any, currentValue: any): boolean {
    return initialValue['id'] === currentValue['id']
      && initialValue['name'] === currentValue['name']
      && initialValue['toolType'] === currentValue['toolType']
      && initialValue['toolLevel'] === currentValue['toolLevel']; 
  }

  protected save(profileId: string, form: FormGroup): Observable<any> {
    return this.profileService.addTool(
      profileId,
      {
        id: form.value['id'],
        name: form.value['name'],
        toolType: form.value['toolType'],
        level: form.value['toolLevel'],
      });
  }
}
