import { Component, OnInit, viewChild } from '@angular/core';
import { UserService } from "../core/services/user.service";
import { User } from "../model/user";
import { ReplaySubject } from "rxjs";
import { AsyncPipe } from '@angular/common';
import { ChangeRoleFormComponent } from './components/change-role-form/change-role-form.component';
import { CreateUserFormComponent } from './components/create-user-form/create-user-form.component';
import { DeleteUserFormComponent } from './components/delete-user-form/delete-user-form.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ImportRecruiteeComponent } from "./components/import-recruitee/import-recruitee.component";
import { ChangeTracker } from '../core/guards/block-nav-if-changes.guard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    AsyncPipe,
    ChangeRoleFormComponent,
    CreateUserFormComponent,
    DeleteUserFormComponent,
    ImportRecruiteeComponent
],
  templateUrl: './admin.component.html'
})
export class AdminComponent extends ChangeTracker implements OnInit {
  protected users$ = new ReplaySubject<User[]>(1);
  protected errorMessage = '';

  public formChangeRole = viewChild<ChangeRoleFormComponent>('changeRoleForm');
  public formCreateUser = viewChild<CreateUserFormComponent>('createUserForm');
  public formDeleteUser = viewChild<DeleteUserFormComponent>('deleteUserForm');
  public formImportRecruitee = viewChild<ImportRecruiteeComponent>('importRecruiteeForm');

  constructor(router: Router, private userService: UserService) {
    super(router);
  }

  public ngOnInit(): void {
    this.userService
      .getAll()
      .subscribe({
        next: (users) => this.users$.next(users.sort(User.compare)),
        error: (err: HttpErrorResponse) => this.errorMessage = err.message
      });
  }

  public onUserCreated(users: User[], createdUser: User): void {
    const newUsers = [...users];
    newUsers.push(createdUser);
    this.users$.next(newUsers.sort(User.compare));
  }

  public onUserUpdated(users: User[], updatedUser: User): void {
    const newUsers = [];

    for (const user of users) {
      if (user.id !== updatedUser.id) {
        newUsers.push(user);
      } else {
        newUsers.push(updatedUser);
      }
    }

    this.users$.next(newUsers);
  }

  public onUserDeleted(users: User[], userToDelete: User): void {
    this.users$.next(users.filter(user => user.id !== userToDelete.id));
  }

  public hasChanges(): boolean {
    return this.formChangeRole()?.hasChanges()
      || this.formCreateUser()?.hasChanges()
      || this.formDeleteUser()?.hasChanges()
      || this.formImportRecruitee()?.hasChanges();
  }
}
