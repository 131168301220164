import { Point } from "src/app/model/point.model";

export class PhotoEditorTouchEventInterpreter {
    private prev: TouchEvent | null = null;

    constructor(
        private onScaleChange: (delta: number) => void,
        private onPositionChange: (delta: Point) => void
    ) {
    }

    public process(e: TouchEvent): void {
        if (e.touches.length > 2) {
            this.prev = null;
            return;
        }
        
        if (this.prev?.touches?.length === e.touches.length) {
            if (e.touches.length === 1) {
                const prevTouch = this.prev.touches[0];
                const newTouch = e.touches[0];

                const delta = new Point(prevTouch.screenX - newTouch.screenX, prevTouch.screenY - newTouch.screenY);
                this.onPositionChange(delta);
            } else if (e.touches.length === 2) {
                const prevDistance = this.getDistance(this.prev.touches[0], this.prev.touches[1]);
                const newDistance = this.getDistance(e.touches[0], e.touches[1]);

                const delta = (newDistance / prevDistance) - 1;
                this.onScaleChange(delta);
            }
        }

        this.prev = e;
        e.preventDefault();
        e.stopPropagation();
    }

    public reset(): void {
        this.prev = null;
    }

    private getDistance(t1: Touch, t2: Touch): number {
        return Math.hypot(t1.pageX - t2.pageX, t1.pageY - t2.pageY);
    }
}
