import { Point } from "src/app/model/point.model";
import { Photo } from "./photo.model";
import * as pica from "pica";

export class Canvas {
    private readonly originalWidth: number;
    private readonly originalHeight: number;
    private readonly context: CanvasRenderingContext2D;
    private _scale = 1.0;
  
    constructor(private element: HTMLCanvasElement) {
      this.originalWidth = element.width;
      this.originalHeight = element.height;
      this.context = element.getContext('2d');
      this.context.imageSmoothingEnabled = false;
    }
  
    public get width(): number {
      return this.element.width;
    }
  
    public get height(): number {
      return this.element.height;
    }

    public get scale(): number {
      return this._scale;
    }
  
    public clear(): void {
      this.context.clearRect(0, 0, this.width, this.height);
    }

    public setScale(scale: number): void {
      this.element.width = this.originalWidth * scale;
      this.element.height = this.originalHeight * scale;
      this._scale = scale;
    }

    public remove(): void {
        this.element.remove();
    }
  
    public drawCanvas(canvas: Canvas, spCanvas: Point, width: number, height: number): void {
      this.context.drawImage(
        canvas.element,
        spCanvas.x,
        spCanvas.y,
        width,
        height,
        0,
        0,
        this.width,
        this.height);
    }
  
    public async drawPhoto(photo: Photo): Promise<Canvas> {
      await pica()
        .resize(
          photo.element,
          this.element,
          {
            // Suggested values by pica: https://github.com/nodeca/pica?tab=readme-ov-file#unsharp-mask
            unsharpAmount: 160,
            unsharpRadius: 0.6,
            unsharpThreshold: 1
          });
      return this;
    }
  
    public toBlob(): Promise<Blob> {
      let blobCreated: (blob: Blob) => void;
      const promise = new Promise<Blob>((resolve, _) => { blobCreated = resolve })
      this.element.toBlob((blob: Blob) => blobCreated(blob));
      return promise;
    }
  }