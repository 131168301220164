export class Point {
  private readonly _x: number;
  private readonly _y: number;
  
  constructor(x: number, y: number) {
    this._x = x;
    this._y = y;
  }

  public get x(): number {
    return this._x;
  }

  public get y(): number {
    return this._y;
  }
  
  public move(xDelta: number, yDelta: number): Point {
    return new Point(this._x + xDelta, this._y + yDelta);
  }

  public moveBy(delta: Point): Point {
    return new Point(this._x + delta._x, this._y + delta._y)
  }

  public divide(denominator: number): Point {
    return new Point(this._x / denominator, this._y / denominator);
  }

  public multiply(product: number): Point {
    return new Point(this._x * product, this._y * product);
  }
}
