import { AfterViewInit, Component, ElementRef, HostListener, input, OnDestroy, output, ViewChild } from '@angular/core';
import { Modal } from 'flowbite';
import { Subscription } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { ButtonComponent } from 'src/app/core/components/basic/button/button.component';
import { Changelog, ChangelogConstraints } from './models/changelog.model';
import { PhotoCanvas } from './models/photo-canvas.model';
import { Photo } from './models/photo.model';
import { Point } from 'src/app/model/point.model';
import { State } from './models/state.model';
import { InputComponent } from '../basic/input/input.component';
import { SpinnerComponent } from "../basic/spinner/spinner.component";
import { ProfileService } from '../../services/profile.service';
import { Profile } from 'src/app/model/profile';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { ModalState } from './models/modal-state.model';
import { PhotoEditorTouchEventInterpreter } from './models/touch-interpreter.model';
import { AsyncPipe } from '@angular/common';
import { IsMobileService } from '../../services/is-mobile.service';

@Component({
  selector: 'app-photo-editor',
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    InputComponent,
    SpinnerComponent
],
  templateUrl: './photo-editor.component.html'
})
export class PhotoEditorComponent implements AfterViewInit, OnDestroy {
  private static readonly photoChangesDetailedRenderDebounceMs = 1000;

  private subscription: Subscription;
  private touchInterpreter: PhotoEditorTouchEventInterpreter;
  private isDraggingMouse = false;

  public changelog: Changelog;
  public errorMessage = '';
  public uploadProgress: number | null = null;

  @ViewChild('modalElem')
  private modalElem: ElementRef;
  private modal: Modal;
  public modalState = new ModalState();

  @ViewChild('canvas')
  public canvasElem: ElementRef<HTMLCanvasElement>;
  public canvas: PhotoCanvas;

  @ViewChild('zoomPercentageInput')
  public zoomPercentageInput: InputComponent;

  public profileId = input.required<string>();
  public onProfileUpdated = output<Profile>();

  constructor(private profileService: ProfileService) {
  }

  public ngAfterViewInit(): void {
    this.modal = new Modal(
      this.modalElem.nativeElement,
      {
        backdrop: 'static'
      });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.canvas?.dispose();
  }

  public open(): void {
    this.subscription = null;
    this.changelog = null;
    this.touchInterpreter = null;
    this.uploadProgress = null;
    this.errorMessage = '';

    this.isDraggingMouse = false;
    this.modalState.reset();
    this.modal.show();
  }

  public close(): void {
    this.subscription?.unsubscribe();
    this.canvas?.dispose();
    this.zoomPercentageInput?.value?.set('');
    this.modal.hide();
  }

  public async onSelectFile(files: FileList): Promise<void> {
    if (files.length !== 1) {
      this.modalState.reset();
    } else {
      this.modalState.photoSelected();

      const photo = await Photo.load(files[0]);
      this.canvas = new PhotoCanvas(this.canvasElem.nativeElement, photo);

      this.changelog = new Changelog(state => this.getConstraints(state));
      this.touchInterpreter = new PhotoEditorTouchEventInterpreter(
        (scaleDelta: number) => this.changelog.changeScale(scaleDelta),
        (posDelta: Point) => this.changelog.changeStartPoint(posDelta.x, posDelta.y)
      );

      let photoIsDrawn: () => void;
      const isDrawingPhoto = new Promise<void>((resolve, _) => { photoIsDrawn = resolve; });

      this.subscription = this.changelog.state$
        .pipe(
          map(state => ({ state: state, correlationId: this.modalState.startRedrawing() })),
          tap(({ state }) => {
            this.updateZoomPercentage(state.scale);
            this.canvas.redrawFast(state);
          }),
          debounceTime(PhotoEditorComponent.photoChangesDetailedRenderDebounceMs)
        )
        .subscribe(({ state, correlationId }) => {
          this.canvas
            .redrawDetailed(state)
            .then(() => {
              photoIsDrawn();

              this.modalState.endRedrawing(correlationId);
            });
        });

      await isDrawingPhoto;
    }
  }

  @HostListener('dragover', [ '$event' ])
  public onDragover(event: DragEvent): void {
    event.preventDefault();
  }

  public async onDropFile(event: DragEvent): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    
    await this.onSelectFile(event.dataTransfer.files);
  }

  public async onSave(): Promise<void> {
    this.modalState.startSaving();

    const onComplete = () => {
      this.uploadProgress = null;
      this.modalState.endSaving();
      this.close();
    };

    const photo = await this.canvas.toFile();

    this.profileService.uploadPhoto(this.profileId(), photo).subscribe({
      next: (event: HttpEvent<Profile>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.uploadProgress = 0;
            break;
          case HttpEventType.UploadProgress:
            this.uploadProgress = event.loaded / event.total;
            break;
          case HttpEventType.Response:
            this.uploadProgress = 100;
            this.onProfileUpdated.emit(event.body);
            onComplete();
            break;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorMessage = err.message
        onComplete();
      }
    });
  }

  public onZoom(event: WheelEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.isDraggingMouse && this.modalState.canPerformPhotoActions()) {
      // A mouse scroll event results in a `deltaY` of 100, but when using a trackpad, the `deltaY` is around 1, so try to normalize the delta.
      const delta = Math.abs(event.deltaY) >= 100 ? event.deltaY / 100 : event.deltaY;

      // Divide the delta by 5 such that one mouse scroll event zooms the photo by 20%.
      // Note: this value is chosen arbitrarily until it 'felt good'.
      this.changelog.changeScale(-delta / 5);
    }
  }

  public setZoomPercentage(newZoomPercentage: string): void {
    const newScale = parseInt(newZoomPercentage.replace('%', '')) / 100;
    if (!this.isDraggingMouse && this.modalState.canPerformPhotoActions() && newScale) {
      this.changelog.changeScale(newScale - this.changelog.state.scale);
    }

    this.updateZoomPercentage(this.changelog.state.scale);
  }

  public onMouseDown(): void {
    this.isDraggingMouse = true;
  }

  public onMouseMove(event: MouseEvent): void {
    if (this.isDraggingMouse && this.modalState.canPerformPhotoActions()) {
      this.changelog.changeStartPoint(-event.movementX, -event.movementY);
    }
  }

  @HostListener('window:mouseup')
  public onMouseUp(): void {
    this.isDraggingMouse = false;
  }

  public onTouchStart(event: TouchEvent): void {
    this.touchInterpreter?.process(event);
  }

  public onTouchMove(event: TouchEvent): void {
    this.touchInterpreter?.process(event);
  }

  @HostListener('window:touchend')
  public onTouchEnd(): void {
    this.touchInterpreter?.reset();
  }

  @HostListener('window:keydown', [ '$event' ])
  public onKeyDown(event: KeyboardEvent): void {
    let isHandled = false;

    if (this.modalState.canPerformPhotoActions()) {
      const isModifierKey = event.ctrlKey || event.metaKey;

      if (isModifierKey && event.key === 'z' && this.changelog?.canUndo) {
        this.changelog?.undo();
        isHandled = true;
      } else if (isModifierKey && event.key === 'y' && this.changelog?.canRedo) {
        this.changelog?.redo();
        isHandled = true;
      } else if (isModifierKey && event.key === '+') {
        this.changelog.changeScale(0.1);
        this.updateZoomPercentage(this.changelog.state.scale);
        isHandled = true;
      } else if (isModifierKey && event.key === '-') {
        this.changelog.changeScale(-0.1);
        this.updateZoomPercentage(this.changelog.state.scale);
        isHandled = true;
      }
    }

    if (isHandled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private getConstraints(state: State): ChangelogConstraints {
    const scaleFactor = state.scale / this.canvas.scale;
    const sizeDiff = new Point(this.canvas.maxWidth * scaleFactor - this.canvas.minWidth, this.canvas.maxHeight * scaleFactor - this.canvas.minHeight);

    return {
      minScale: 1,
      minStartPoint: sizeDiff.divide(-2),
      maxStartPoint: sizeDiff.divide(2)
    };
  }

  private updateZoomPercentage(scale: number): void {
    const percentage = (scale * 100).toFixed(0);
    const percentageStr = `${percentage}%`;

    this.zoomPercentageInput.value.set(percentageStr);
  }
}
