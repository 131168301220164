<div class="flex flex-col">
    <div class="flex flex-row mb-3 items-center">
        <h2 i18n class="text-sm font-semibold flex-1">Tools</h2>
        <button i18n-title type="button" title="Add new tool" tabindex="-1" (click)="addTool()" class="text-gray-500 hover:text-gray-700">
            <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
            </svg>
        </button>
    </div>
    @if (isLoading) {
        <div class="grid flex-1 gap-2 grid-cols-1 md:gap-4 md:grid-cols-2">
            @for (_ of [].constructor(4); track $index) {
                <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
            }
        </div>
    } @else {
        <div class="grid flex-1 gap-2 grid-cols-1 md:gap-4 md:grid-cols-2" dndDropzone (dndDrop)="onDropTool($event)">
            <div class="py-2 bg-gray-50 border border-gray-200 rounded-lg" dndPlaceholderRef>
                <span class="font-medium collapse">Placeholder</span>
            </div>
            @for (control of controlArray.controls; track $index) {
                <div class="relative z-0 w-full group flex items-center" [dndDraggable]="$index" dndEffectAllowed="move" dndDragImageRef>
                    <div class="absolute end-0 pr-2 flex flex-row gap-1">
                        <button i18n-title type="button" title="Delete tool" (click)="onDeleteTool($index)" tabindex="-1" class="text-gray-300 hover:text-gray-600">
                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                            </svg>
                        </button>
                        <div dndHandle>
                            <button i18n-title type="button" title="Move tool" data-drag-trigger tabindex="-1" class="text-gray-300 hover:text-gray-600 cursor-move">
                                <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" x="128" y="128" role="img" style="display:inline-block;vertical-align:middle" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="currentColor" d="M9.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L10.5 3.707V6.5a.5.5 0 0 1-1 0V3.707L8.354 4.854a.5.5 0 1 1-.708-.708l2-2ZM10 12a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm0-1a1 1 0 1 1 0-2a1 1 0 0 1 0 2Zm5.854 1.354l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 0 0-.708.708L16.293 9.5H13.5a.5.5 0 0 0 0 1h2.793l-1.147 1.146a.5.5 0 0 0 .708.708Zm-5.5 5.5l2-2a.5.5 0 0 0-.708-.708L10.5 16.293V13.5a.5.5 0 0 0-1 0v2.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0Zm-8.208-7.5a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L3.707 9.5H6.5a.5.5 0 0 1 0 1H3.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2Z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <app-input-with-suggestions class="flex-1 border-none" inputType="text" [value]="control.value" [formControl]="control" [suggestions]="suggestions" additionalClasses="pe-14"></app-input-with-suggestions>
                </div>
            }
        </div>
    }
</div>
<app-modal-delete #modalDelete i18n-modalDeleteText modalDeleteText="Are you sure you want to delete this tool?" (onDelete)="deleteTool($event)"></app-modal-delete>
