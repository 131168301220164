export class Photo {
    private readonly _name: string;

    constructor(public element: HTMLImageElement, name: string) {
      this._name = Photo.nameWithoutExtension(name);
    }
  
    public get width(): number {
      return this.element.width;
    }
  
    public get height(): number {
      return this.element.height;
    }

    public get name(): string {
      return this._name;
    }
  
    public static load(file: File): Promise<Photo> {
      return new Promise<Photo>((resolve, _) => {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.onload = () => {
            const photo = new Photo(image, file.name);
            resolve(photo);
          };
          image.src = reader.result as string;
        };
        reader.readAsDataURL(file);
      });
    }

    private static nameWithoutExtension(name: string): string {
      if (name.indexOf('.') === -1) {
        return name;
      } else {
        const split = name.split('.');
        return split
          .slice(0, split.length - 1)
          .join('.');
      }
    }
  }