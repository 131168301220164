export class Education {
  id: string;
  nameEducationNL: string;
  nameEducationEN: string;
  nameInstitute: string;
  educationLevel: EducationLevelDto;
  startDate: string;
  endDate?: string;
  graduated = true;
}

export enum EducationLevelDto {
  MBO = 'MBO',
  HBO = 'HBO',
  WO = 'WO',
  BSC = 'BSC',
  MSC = 'MSC',
}

export enum EducationLevel {
  MBO = 'MBO',
  BSC = 'BSC',
  MSC = 'MSC',
}

export function educationLevelFromDto(dto: EducationLevelDto): EducationLevel {
  switch (dto) {
    case EducationLevelDto.MBO: return EducationLevel.MBO;
    case EducationLevelDto.HBO: return EducationLevel.BSC;
    case EducationLevelDto.WO: return EducationLevel.MSC;
    case EducationLevelDto.BSC: return EducationLevel.BSC;
    case EducationLevelDto.MSC: return EducationLevel.MSC;
  }
}

export function educationLevelToDto(educationLevel: EducationLevel): EducationLevelDto {
  switch (educationLevel) {
    case EducationLevel.MBO: return EducationLevelDto.MBO;
    case EducationLevel.BSC: return EducationLevelDto.BSC;
    case EducationLevel.MSC: return EducationLevelDto.MSC;
  }
}

export function educationLevelToName(educationLevel: EducationLevel): string {
  switch (educationLevel) {
    case EducationLevel.MBO: return $localize`MBO`;
    case EducationLevel.BSC: return $localize`BSc`;
    case EducationLevel.MSC: return $localize`MSc`;
  }
}
