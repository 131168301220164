import { Point } from "src/app/model/point.model";

export class State {
    private readonly _scale: number;
    private readonly _startPoint: Point;

    constructor(scale = 1.0, startPoint = new Point(0, 0)) {
        this._scale = scale;
        this._startPoint = startPoint;
    }

    public get scale(): number {
        return this._scale;
    }

    public get startPoint(): Point {
        return this._startPoint;
    }
}
