import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextService {
  /**
   * Duplicates the new line '\n' characters in the provided text. This is used for `textarea` HTML elements to clearly show the paragraphs as the element does not support a paragraph distance.
   * So, this is a workaround to mimic the situation.
   * @param text The text in which the new line characters are to be duplicated.
   * @returns The provided text, but each new line characters is duplicated.
   */
  public addDuplicateNewlines(text: string): string {
    return this
      .removeDuplicateNewLines(text)
      .split('\n')
      .join('\n\n');
  }

  /**
   * Removes consecutive new line '\n' characters and replaces them with single new line '\n' character in the provided text. Sending multiple new line characters to the API causes the CV export to have to much whitespace.
   * This reverts the workaround from the `addDuplicateNewLines` function. 
   * @param text The text from which the duplicate new line characters are to be removed.
   * @returns The provided text, but with no consecutive new line characters.
   */
  public removeDuplicateNewLines(text: string): string {
    if (!text)
      return '';

    return text
      .split('\n')
      .map(paragraph => paragraph.trim())
      .filter(paragraph => paragraph !== '')
      .join('\n');
  }
}
