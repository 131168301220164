<div #modalElem tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="p-4 w-full max-w-2xl max-h-full">
        <div class="bg-white rounded-lg shadow">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <h2 i18n class="text-xl font-semibold text-gray-900">Photo Editor</h2>
            </div>
            <div class="flex flex-col items-center gap-4 p-4">
                <div class="flex flex-col gap-4">
                    <div class="flex flex-row gap-4 items-center">
                        <app-input #zoomPercentageInput inputType="text" [isDisabled]="!modalState.canPerformPhotoActions()" (focusout)="setZoomPercentage($event.target.value)" (keydown.enter)="setZoomPercentage($event.target.value)" class="w-24"></app-input>
                        <button type="button" i18n-title title="Undo" [disabled]="!modalState.canPerformPhotoActions() || !changelog?.canUndo" (click)="changelog.undo()" class="w-6 h-6 text-gray-700 disabled:text-gray-300 enabled:hover:text-gray-900">
                            <svg style="transform: scale(-1,1)" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z"/>
                            </svg>
                        </button>
                        <button type="button" i18n-title title="Redo" [disabled]="!modalState.canPerformPhotoActions() || !changelog?.canRedo" (click)="changelog.redo()"class="w-6 h-6 text-gray-700 disabled:text-gray-300 enabled:hover:text-gray-900">
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z"/>
                            </svg>
                        </button>
                        <div class="flex-1 flex flex-row-reverse">
                            <app-spinner class="block w-6 h-6" [class.invisible]="!modalState.isPhotoLoading()" i18n-title title="Busy with redrawing the photo in more detail"></app-spinner>
                        </div>
                    </div>
                    <div class="relative">
                        <canvas #canvas width="455" height="510" class="!m-0 w-full h-full max-h-[50vh]" [class.invisible]="!modalState.hasPhoto()" (wheel)="onZoom($event)" (mousedown)="onMouseDown()" (mousemove)="onMouseMove($event)" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)"></canvas>
                        @if (!modalState.hasPhoto()) {
                            <div class="absolute top-0 left-0 w-full h-full z-75 flex flex-col gap-4 justify-center items-center p-4 border border-dashed border-gray-400" (drop)="onDropFile($event)">
                                <input #fileUpload type="file" accept="image/*" class="hidden" (change)="onSelectFile($event.target.files)">
                                <svg class="w-16 h-16" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path fill-rule="evenodd" d="M12 3a1 1 0 0 1 .78.375l4 5a1 1 0 1 1-1.56 1.25L13 6.85V14a1 1 0 1 1-2 0V6.85L8.78 9.626a1 1 0 1 1-1.56-1.25l4-5A1 1 0 0 1 12 3ZM9 14v-1H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-4v1a3 3 0 1 1-6 0Zm8 2a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z" clip-rule="evenodd"/>
                                </svg>
                                <p i18n class="text-center">Drag and drop photo here</p>
                                <p i18n class="text-center">or</p>
                                <app-button type="button" color="primary" i18n-label label="Browse for photo" i18n (onBtnClick)="fileUpload.click()"></app-button>
                            </div>
                        }
                        @if (modalState.isPhotoBlocked()) {
                            <div class="absolute top-0 left-0 w-full h-full z-100 bg-gray-700/50 animate-pulse"></div>
                        }
                    </div>
                </div>
                @if (errorMessage) {
                    <p class="text-red-600">{{ errorMessage }}</p>
                }
            </div>
            <div class="w-full bg-gray-200 h-1.5">
                @if (uploadProgress != null) {
                    <div class="bg-alfa1-brand-primary-500 h-1.5" [style.width]="uploadProgress + '%'"></div>
                }
            </div>
            <div class="flex flex-row gap-4 p-4 border-t border-gray-200 rounded-b">
                <app-button type="button" color="secondary" label="Cancel" (onBtnClick)="close()" class="flex-1"></app-button>
                <app-button type="button" color="primary" label="Save" [isDisabled]="!modalState.canSave()" [isPerformingAction]="modalState.isSaving()" (onBtnClick)="onSave()" class="flex-1"></app-button>
            </div>
        </div>
    </div>
</div>
