@if (isLoading()) {
    <div class="w-full h-[41.6px] bg-gray-300 rounded-xl animate-pulse"></div>
} @else {
  <div class="relative">
    <input #inputElem [id]="id()" [type]="inputType()" [value]="value()" [autocomplete]="autocomplete()" [placeholder]="placeholder()" [required]="isRequired()" [attr.minLength]="minLength()" [(disabled)]="isDisabled" (input)="onInputChange($event.target.value)" [class]="classes()" [attr.list]="'suggestions-' + random()"/>
    @if (suggestions()?.length > 0) {
      <datalist [id]="'suggestions-' + random()">
        @for (suggestion of filteredSuggestions; track suggestion) {
        <option [value]="suggestion">
          {{ suggestion }}
        </option>
        }
      </datalist>
    }
  </div>
}
