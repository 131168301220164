import { booleanAttribute, Component, computed, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { LabelComponent } from 'src/app/core/components/basic/label/label.component';
import { SelectComponent } from 'src/app/core/components/basic/select/select.component';
import { FormButtonsComponent } from 'src/app/core/components/form/form-buttons/form-buttons.component';
import { FormCheckboxComponent } from 'src/app/core/components/form/form-checkbox/form-checkbox.component';
import { FormSelectComponent } from 'src/app/core/components/form/form-select/form-select.component';
import { ChangeTrackerForm } from 'src/app/core/guards/block-nav-if-changes.guard';
import { UserService } from 'src/app/core/services/user.service';
import { Companies } from 'src/app/model/company.model';
import { Role } from 'src/app/model/role';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-change-role-form',
  standalone: true,
  imports: [
    FormButtonsComponent,
    FormCheckboxComponent,
    FormSelectComponent,
    LabelComponent,
    ReactiveFormsModule,
    SelectComponent
  ],
  templateUrl: './change-role-form.component.html'
})
export class ChangeRoleFormComponent extends ChangeTrackerForm {
  protected selectedUser: User | null = null;
  protected form = this.createForm(null);
  protected isBusy = false;
  protected errorMessage = '';

  protected userOptions = computed(() => this.users().map(user => ({
    value: user.id,
    label: `${user.name} (${Companies.companyToName(user.company)})`
  })));

  public users = input<User[]>([]);
  public isLoading = input(false, { transform: booleanAttribute });
  public onUserChanged = output<User>();

  constructor(router: Router,private userService: UserService) {
    super(router);
  }

  public onUserSelect(userId: string): void {
    if (userId) {
      const userToSelect = this.users().find(user => user.id === userId);
      if (userToSelect !== undefined) {
        this.selectedUser = userToSelect;
        this.form = this.createForm(userToSelect);
      }
    } else {
      this.selectedUser = null;
      this.form = this.createForm(null);
    }
  }

  public onFormSubmit(): void {
    if (this.selectedUser !== null && this.form.valid && this.hasChanges()) {
      this.isBusy = true;
      this.errorMessage = '';
      const roles = [ Role.AUTHENTICATED ];

      if (this.form.value['isProfessional'] === true) {
        roles.push(Role.PROFESSIONAL);
      }
      if (this.form.value['isSales'] === true) {
        roles.push(Role.SALES);
      }
      if (this.form.value['isAdmin'] === true) {
        roles.push(Role.ADMIN);
      }

      this.selectedUser.roles = roles;

      this.userService
        .update(this.selectedUser)
        .subscribe({
          next: (user: User) => {
            this.onUserSelect(null);
            this.onUserChanged.emit(user);
            this.trackedFormSubmitted();
            this.isBusy = false;
          },
          error: (err) => {
            this.errorMessage = err.message;
            this.isBusy = false;
          }
        });
    }
  }

  protected areFormValuesEqual(initialValue: any, currentValue: any): boolean {
    return initialValue['isProfessional'] === currentValue['isProfessional']
      && initialValue['isSales'] === currentValue['isSales']
      && initialValue['isAdmin'] === currentValue['isAdmin'];
  }

  private createForm(user: User | null): FormGroup {
    this.trackReset();

    const form = new FormGroup({
      isProfessional: new FormControl({ value: user?.isProfessional ?? false, disabled: user == null }),
      isSales: new FormControl({ value: user?.isSales ?? false, disabled: user == null }),
      isAdmin: new FormControl({ value: user?.isAdmin ?? false, disabled: user == null }),
    });

    this.trackForm(form);
    return form;
  }
}
