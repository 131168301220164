export class ModalState {
    private value: 'no-photo' | 'loading-photo' | 'editing' | 'redrawing' | 'saving' | 'done' = 'no-photo';
    private correlationId: string | null;

    public reset(): void {
        this.value = 'no-photo';
        this.correlationId = null;
    }

    public photoSelected(): void {
        this.value = 'loading-photo';
        this.correlationId = null;
    }

    public startRedrawing(): string {
        if (this.value !== 'loading-photo') {
            this.value = 'redrawing';
        }
        
        const correlation = crypto.randomUUID();
        this.correlationId = correlation;
        return correlation;
    }

    public endRedrawing(correlationId: string): void {
        if (this.correlationId === correlationId) {
            this.value = 'editing';
            this.correlationId = null;
        }
    }

    public startSaving(): void {
        if (this.value === 'editing') {
            this.value = 'saving';
        }
    }

    public endSaving(): void {
        if (this.value === 'saving') {
            this.value = 'done';
        }
    }

    public canPerformPhotoActions(): boolean {
        return this.value === 'editing' || this.value === 'redrawing';
    }

    public hasPhoto(): boolean {
        return this.value !== 'no-photo';
    }

    public isPhotoBlocked(): boolean {
        return this.value === 'loading-photo' || this.value === 'saving';
    }

    public isPhotoLoading(): boolean {
        return this.value === 'loading-photo' || this.value === 'redrawing';
    }

    public canSave(): boolean {
        return this.value === 'editing';
    }

    public isSaving(): boolean {
        return this.value === 'saving';
    }
}