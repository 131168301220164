import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Profile } from 'src/app/model/profile';
import { Summary } from 'src/app/model/summary';
import { FormInputComponent } from "../../../core/components/form/form-input/form-input.component";
import { FormTextareaComponent } from 'src/app/core/components/form/form-textarea/form-textarea.component';
import { FormLanguageContainerComponent } from 'src/app/core/components/form/form-language-container/form-language-container.component';
import { AutogrowDirective } from 'src/app/core/directives/autogrow.directive';
import { FormButtonsComponent } from 'src/app/core/components/form/form-buttons/form-buttons.component';
import { BaseProfileDataForm } from '../base-profile-data-form';
import { TextService } from 'src/app/core/services/text.service';

@Component({
  selector: 'app-summary-form',
  standalone: true,
  imports: [
    AsyncPipe,
    AutogrowDirective,
    FormButtonsComponent,
    FormLanguageContainerComponent,
    FormInputComponent,
    FormTextareaComponent,
    ReactiveFormsModule
],
  templateUrl: './summary-form.component.html'
})
export class SummaryFormComponent extends BaseProfileDataForm<Summary> {
  @Input({ required: true })
  public id = '';
  public dataName = $localize`summary`;

  constructor(route: ActivatedRoute, router: Router, profileService: ProfileService, private textService: TextService) {
    super(route, router, profileService);
  }

  protected getInitialValue(profile: Profile, valueId: string): Summary | null {
    return profile.summaries.find(summary => summary.id === valueId);
  }

  protected save(profileId: string, form: FormGroup): Observable<any> {
    return this.profileService.addSummary(
      profileId,
      {
        id: form.value['id'],
        name: form.value['name'],
        summaryNL: this.textService.removeDuplicateNewLines(form.value['nl']),
        summaryEN: this.textService.removeDuplicateNewLines(form.value['en'])
      });
  }

  protected areFormValuesEqual(initialValue: any, currentValue: any): boolean {
    return initialValue['id'] === currentValue['id']
      && initialValue['name'] === currentValue['name']
      && this.textService.removeDuplicateNewLines(initialValue['nl']) === this.textService.removeDuplicateNewLines(currentValue['nl'])
      && this.textService.removeDuplicateNewLines(initialValue['en']) === this.textService.removeDuplicateNewLines(currentValue['en']);
  }

  public createForm(summary: Summary | null): FormGroup {
    return new FormGroup({
      id: new FormControl(summary?.id),
      name: new FormControl(summary?.name, Validators.required),
      nl: new FormControl(this.textService.addDuplicateNewlines(summary?.summaryNL), Validators.required),
      en: new FormControl(this.textService.addDuplicateNewlines(summary?.summaryEN), Validators.required)
    });
  }
}
