import { Canvas } from "./canvas.model";
import { Photo } from "./photo.model";
import { State } from "./state.model";

export class PhotoCanvas {
  /**
   * The canvas that is shown in the DOM. This canvas has a fixed size and is the viewport for a part of the offscreen canvas.
   */
  private readonly finalCanvas: Canvas;

  /**
   * The offscreen canvas is not added to the DOM. This canvas contains the photo and is resized based on the zoom level.
   */
  private readonly offscreenCanvas: Canvas;

  constructor(canvas: HTMLCanvasElement, private photo: Photo) {
    this.finalCanvas = new Canvas(canvas);

    // Calculate the size for the offscreen canvas. It should keep the ratio of the photo.
    const widthRatio = canvas.width / photo.width;
    const heightRatio = canvas.height / photo.height;
    const ratio = Math.max(widthRatio, heightRatio);

    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = photo.width * ratio;
    offscreenCanvas.height = photo.height * ratio;
    this.offscreenCanvas = new Canvas(offscreenCanvas);
  }

  public get minHeight(): number {
    return this.finalCanvas.height;
  }

  public get maxHeight(): number {
    return this.offscreenCanvas.height;
  }

  public get minWidth(): number {
    return this.finalCanvas.width;
  }

  public get maxWidth(): number {
    return this.offscreenCanvas.width;
  }

  public get scale(): number {
    return this.offscreenCanvas.scale;
  }

  public redrawFast(state: State): void {
    const scaleFactor = state.scale / this.offscreenCanvas.scale;

    const width = this.finalCanvas.width / scaleFactor;
    const height = this.finalCanvas.height / scaleFactor;
    const x = (this.offscreenCanvas.width - width) / 2;
    const y = (this.offscreenCanvas.height - height) / 2;

    const startPoint = state.startPoint.divide(scaleFactor).move(x, y);

    this.finalCanvas.clear();
    this.finalCanvas.drawCanvas(this.offscreenCanvas, startPoint, width, height);
  }

  public async redrawDetailed(state: State): Promise<void> {
    this.offscreenCanvas.setScale(state.scale);
    this.offscreenCanvas.clear();
    await this.offscreenCanvas.drawPhoto(this.photo);
    this.redrawFast(state);
  }

  public async toFile(): Promise<File> {
    const blob = await this.finalCanvas.toBlob();
    const fileExtension = blob.type.split('/')[1];
    return new File([blob], `${this.photo.name}.${fileExtension}`, { type: blob.type });
  }

  public dispose(): void {
    this.finalCanvas.clear();
    this.offscreenCanvas.remove();
  }
}