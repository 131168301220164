import { State } from "./state.model";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { Point } from "src/app/model/point.model";

export class Changelog {
    public static readonly mergeChangesWithinMs = 1000;

    private _getConstraints: (state: State) => ChangelogConstraints;
    private _changes: PhotoEditorChange[];
    private _changesIdx = -1;
    private _currentState: State;
    private _sub = new ReplaySubject<State>(1);

    constructor(getConstraints?: (state: State) => ChangelogConstraints) {
        this._getConstraints = getConstraints;
        this.init();
    }

    public get state$(): Observable<State> {
        return this._sub;
    }

    public get scale$(): Observable<number> {
        return this.state$.pipe(map(state => state.scale));
    }

    public get canUndo(): boolean {
        return this._changesIdx >= 0;
    }

    public get canRedo(): boolean {
        return this._changesIdx < this._changes.length - 1;
    }

    public get state(): State {
        return this._currentState;
    }

    public clear(): void {
        this.init();
    }

    public changeScale(delta: number): void {
        const constraints = this._getConstraints(this._currentState);
        const deltaAfterConstraints = this.calculateDeltaWithConstraints(this._currentState.scale, delta, constraints?.minScale, constraints?.maxScale);
        if (deltaAfterConstraints !== 0) {
            const change = new PhotoEditorChangeScale(deltaAfterConstraints);

            // Check if we need to move the start point as it may now be out of bounds due to the scaling.
            const stateAfterChange = change.apply(this._currentState);
            const startPointChange = this.getStartPointChange(0, 0, stateAfterChange);
            if (startPointChange === null) {
                // If the start point does not need to move, only apply the scaling change. Otherwise apply both.
                this.applyChange(change);
            } else {
                const multiChange = new PhotoEditorChangeMultiple(change, startPointChange);
                this.applyChange(multiChange);
            }
        }
    }

    public changeStartPoint(xDelta: number, yDelta: number): void {
        const change = this.getStartPointChange(xDelta, yDelta, this._currentState);
        if (change !== null) {
            this.applyChange(change);
        }
    }

    public undo(): void {
        if (this.canUndo) {
            const changeToUndo = this._changes[this._changesIdx];
            this._changesIdx--;

            this._currentState = changeToUndo.revert(this._currentState);
            this._sub.next(this._currentState);
        }
    }

    public redo(): void {
        if (this.canRedo) {
            this._changesIdx++;
            const changeToRedo = this._changes[this._changesIdx];

            this._currentState = changeToRedo.apply(this._currentState);
            this._sub.next(this._currentState);
        }
    }

    private init(): void {
        this._changes = [];
        this._currentState = new State();
        this._sub.next(this._currentState);
    }

    private getStartPointChange(xDelta: number, yDelta: number, state: State): PhotoEditorChangeStartPoint | null {
        const constraints = this._getConstraints(state);
        const xDeltaAfterConstraints = this.calculateDeltaWithConstraints(this._currentState.startPoint.x, xDelta, constraints?.minStartPoint?.x, constraints?.maxStartPoint?.x);
        const yDeltaAfterConstraints = this.calculateDeltaWithConstraints(this._currentState.startPoint.y, yDelta, constraints?.minStartPoint?.y, constraints?.maxStartPoint?.y);

        if (xDeltaAfterConstraints !== 0 || yDeltaAfterConstraints !== 0) {
            return new PhotoEditorChangeStartPoint(xDeltaAfterConstraints, yDeltaAfterConstraints);
        } else {
            return null;
        }
    }

    private applyChange(change: PhotoEditorChange): void {
        // Remove all reverted changes upon making a new change.
        if (this._changesIdx < this._changes.length - 1) {
            this._changes.splice(this._changesIdx + 1);
        }

        if (this._changes.length === 0) {
            this._changes.push(change);
            this._changesIdx++;
        } else {
            // Merge the last change with this change if needed. Otherwise, push it to the changes.
            const lastChange = this._changes[this._changes.length - 1];
            const mergedChange = change.merge(lastChange);

            if (mergedChange) {
                this._changes.splice(this._changes.length - 1, 1, mergedChange);
            } else {
                this._changes.push(change);
                this._changesIdx++;
            }
        }

        this._currentState = change.apply(this._currentState);
        this._sub.next(this._currentState);
    }

    /**
     * Calculates the delta that should be applied based on the provided values and constraints.
     * @param currentValue The current value.
     * @param delta The requested delta that is to be applied to the value.
     * @param minimumValue The minimum value that it can have, or `null` if there is no minimum value.
     * @param maximumValue The maximum value that it can have, or `null` if there is no maximum value.
     * @returns The delta that can actually be applied to the current value based on the requested delta and the provided constraints.
     */
    private calculateDeltaWithConstraints(currentValue: number, delta: number, minimumValue: number | null, maximumValue: number | null): number {
        if (minimumValue != null && currentValue + delta < minimumValue) {
            return minimumValue - currentValue;
        } else if (maximumValue != null && currentValue + delta > maximumValue) {
            return maximumValue - currentValue;
        } else {
            return delta;
        }
    }
}

export interface ChangelogConstraints {
    minScale?: number,
    maxScale?: number,
    minStartPoint?: Point,
    maxStartPoint?: Point
}

interface PhotoEditorChange {
    apply(state: State): State;
    revert(state: State): State;
    merge(change: PhotoEditorChange): PhotoEditorChange | null;
}

class PhotoEditorChangeMultiple implements PhotoEditorChange {
    private readonly _changes: PhotoEditorChange[];

    constructor(...changes: PhotoEditorChange[]) {
        this._changes = changes;
    }

    public apply(state: State): State {
        return this._changes.reduce((currentState, change) => change.apply(currentState), state);
    }

    public revert(state: State): State {
        return this._changes.reduceRight((currentState, change) => change.revert(currentState), state);
    }

    public merge(_: PhotoEditorChange): PhotoEditorChange | null {
        return null;
    }
}

class PhotoEditorChangeScale implements PhotoEditorChange {
    private readonly _ts = new Date().getTime();

    constructor(private _delta: number) {
    }

    public get delta(): number {
        return this._delta;
    }

    public apply(state: State): State {
        const newScale = this.roundScale(state.scale + this._delta);
        return new State(newScale, state.startPoint);
    }

    public revert(state: State): State {
        const newScale = this.roundScale(state.scale - this._delta);
        return new State(newScale, state.startPoint);
    }

    public merge(change: PhotoEditorChange): PhotoEditorChange | null {
        if (change instanceof PhotoEditorChangeScale && Math.abs(this._ts - change._ts) <= Changelog.mergeChangesWithinMs) {
            return new PhotoEditorChangeScale(this._delta + change._delta);
        }

        return null;
    }

    private roundScale(scale: number): number {
        const fixedScale = scale.toFixed(2);
        return parseFloat(fixedScale);
    }
}

class PhotoEditorChangeStartPoint implements PhotoEditorChange {
    private readonly _ts = new Date().getTime();

    constructor(private _xDelta: number, private _yDelta: number) {
    }

    public get xDelta(): number {
        return this._xDelta;
    }

    public get yDelta(): number {
        return this._yDelta;
    }

    public apply(state: State): State {
        return new State(state.scale, state.startPoint.move(this.xDelta, this.yDelta));
    }

    public revert(state: State): State {
        return new State(state.scale, state.startPoint.move(-this.xDelta, -this.yDelta));
    }

    public merge(change: PhotoEditorChange): PhotoEditorChange | null {
        if (change instanceof PhotoEditorChangeStartPoint && Math.abs(this._ts - change._ts) <= Changelog.mergeChangesWithinMs) {
            return new PhotoEditorChangeStartPoint(this.xDelta + change.xDelta, this.yDelta + change.yDelta);
        }

        return null;
    }
}
