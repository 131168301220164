import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { checkDateAfter } from 'src/app/core/validation/check-date-after';
import { Education, EducationLevel, educationLevelFromDto, educationLevelToDto, educationLevelToName } from 'src/app/model/education';
import { Profile } from 'src/app/model/profile';
import { DatePickerDirective } from 'src/app/core/directives/date-picker.directive';
import { BaseProfileDataForm } from '../base-profile-data-form';
import { SelectOption } from 'src/app/core/components/basic/select/select-option.model';
import { FormInputComponent } from 'src/app/core/components/form/form-input/form-input.component';
import { FormLanguageContainerComponent } from 'src/app/core/components/form/form-language-container/form-language-container.component';
import { FormSelectComponent } from 'src/app/core/components/form/form-select/form-select.component';
import { FormButtonsComponent } from 'src/app/core/components/form/form-buttons/form-buttons.component';
import { FormCheckboxComponent } from 'src/app/core/components/form/form-checkbox/form-checkbox.component';

@Component({
  selector: 'app-education-form',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePickerDirective,
    FormButtonsComponent,
    FormCheckboxComponent,
    FormInputComponent,
    FormLanguageContainerComponent,
    FormSelectComponent,
    ReactiveFormsModule
  ],
  templateUrl: './education-form.component.html',
})
export class EducationFormComponent extends BaseProfileDataForm<Education> {
  @Input()
  public id: string;
  public dataName = $localize`education`;

  public educationLevels: SelectOption<string>[] = Object
    .values(EducationLevel)
    .map(educationLevel => ({
      value: EducationLevel[educationLevel],
      label: educationLevelToName(educationLevel)
    }));

  constructor(route: ActivatedRoute, router: Router, profileService: ProfileService, private dateService: DateService) {
    super(route, router, profileService);
  }

  protected getInitialValue(profile: Profile, valueId: string): Education | null {
    return profile.educations.find(education => education.id === valueId);
  }

  protected createForm(education: Education | null): FormGroup {
    return new FormGroup({
      id: new FormControl(education?.id),
      nameInstitute: new FormControl(education?.nameInstitute, Validators.required),
      educationLevel: new FormControl(education ? educationLevelFromDto(education.educationLevel) : null, Validators.required),
      nlName: new FormControl(education?.nameEducationNL, Validators.required),
      enName: new FormControl(education?.nameEducationEN),
      startDate: new FormControl(this.dateService.toUiDate(education?.startDate), Validators.required),
      endDate: new FormControl(this.dateService.toUiDate(education?.endDate), checkDateAfter(this.dateService, 'startDate')),
      hasGraduated: new FormControl(education?.graduated ?? true, Validators.required)
    });
  }

  protected areFormValuesEqual(initialValue: any, currentValue: any): boolean {
    return initialValue['id'] === currentValue['id']
      && initialValue['nameInstitute'] === currentValue['nameInstitute']
      && initialValue['educationLevel'] === currentValue['educationLevel']
      && initialValue['nlName'] === currentValue['nlName']
      && (initialValue['enName'] ?? '') === (currentValue['enName'] ?? '')
      && this.dateService.toApiDate(initialValue['startDate']) === this.dateService.toApiDate(currentValue['startDate'])
      && this.dateService.toApiDate(initialValue['endDate']) === this.dateService.toApiDate(currentValue['endDate'])
      && initialValue['hasGraduated'] === currentValue['hasGraduated'];
  }

  protected save(profileId: string, form: FormGroup): Observable<any> {
    return this.profileService.addEducation(
      profileId,
      {
        id: form.value['id'],
        nameInstitute: form.value['nameInstitute'],
        educationLevel: educationLevelToDto(form.value['educationLevel']),
        nameEducationNL: form.value['nlName'],
        nameEducationEN: form.value['enName'] ?? '',
        startDate: this.dateService.toApiDate(form.value['startDate']),
        endDate: this.dateService.toApiDate(form.value['endDate']),
        graduated: form.value['hasGraduated']
      });
  }
}
