import { booleanAttribute, Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Companies } from 'src/app/model/company.model';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/model/user';
import { FormInputComponent } from 'src/app/core/components/form/form-input/form-input.component';
import { FormSelectComponent } from 'src/app/core/components/form/form-select/form-select.component';
import { FormButtonsComponent } from 'src/app/core/components/form/form-buttons/form-buttons.component';
import { ChangeTrackerForm } from 'src/app/core/guards/block-nav-if-changes.guard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-user-form',
  standalone: true,
  imports: [
    FormButtonsComponent,
    FormInputComponent,
    FormSelectComponent,
    ReactiveFormsModule
],
  templateUrl: './create-user-form.component.html'
})
export class CreateUserFormComponent extends ChangeTrackerForm {
  protected companyOptions = Companies.getAllCompanyOptions();
  protected form = this.createForm();

  protected isBusy = false;
  public errorMessage = '';
  public isLoading = input(false, { transform: booleanAttribute });
  public onUserCreated = output<User>();

  constructor(router: Router, private userService: UserService) {
    super(router);
  }

  public onSubmit() {
    if (this.form.dirty && this.form.valid && this.hasChanges()) {
      this.isBusy = true;
      this.errorMessage = '';

      this.userService
        .create(
          this.form.value['name'],
          this.form.value['email'],
          this.form.value['company']
        )
        .subscribe({
          next: (user: User) => {
            this.form = this.createForm();
            this.onUserCreated.emit(user);
            this.isBusy = false;
          },
          error: (err) => {
            this.errorMessage = err.message;
            this.isBusy = false;
          }
        });
    }
  }

  protected areFormValuesEqual(initialValue: any, currentValue: any): boolean {
    return initialValue['name'] === currentValue['name']
      && initialValue['email'] === currentValue['email']
      && initialValue['company'] === currentValue['company'];
  }

  private createForm(): FormGroup {
    this.trackReset();

    const form = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      company: new FormControl(null, Validators.required)
    });

    this.trackForm(form);
    return form;
  }
}
