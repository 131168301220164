import { booleanAttribute, Component, computed, input, output } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { Profile } from 'src/app/model/profile';
import { HttpErrorResponse } from '@angular/common/http';
import { DropdownDirective } from '../../directives/dropdown.directive';
import { PhotoEditorComponent } from '../photo-editor/photo-editor.component';

@Component({
  selector: 'app-profile-photo',
  standalone: true,
  imports: [
    DropdownDirective,
    PhotoEditorComponent
  ],
  templateUrl: './profile-photo.component.html'
})
export class ProfilePhotoComponent {
  public input = input.required<{ profileId: string, photoUrl: string } | 'loading'>();
  public allowEdit = input(false, { transform: booleanAttribute });
  public isRounded = input(false, { transform: booleanAttribute });
  public onProfileChanged = output<Profile>();

  protected data = computed(() => {
    const params = this.input();
    return params === 'loading' ? null : params;
  });

  protected isPerformingAction = false;
  protected errorMessage = '';

  constructor(private profileService: ProfileService) {
  }

  public onPhotoChanged(profile: Profile) {
    this.onProfileChanged.emit(profile);
  }

  public deletePhoto(profileId: string): void {
    this.errorMessage = '';
    this.isPerformingAction = true;

    this.profileService
      .deleteCurrentPhoto(profileId)
      .subscribe({
        next: (profile) => {
          this.onProfileChanged.emit(profile);
          this.isPerformingAction = false;
        },
        error: (err: HttpErrorResponse) => {
          this.errorMessage = err.message;
          this.isPerformingAction = false;
        }
      });
  }
}
